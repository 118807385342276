import React from "react";
import { ModuleListShimmer } from "../../../utils/certificateShimmer/CourseListShimmer";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

function ModuleCompletion(props) {
  const { certificateState, shimmer } = props;
  const getTranslateText = useSelector(getTranslatedTextSelector);

  return (
    <>
      <div className="flex md:bg-[#fff] md:h-[calc(100vh_-_40vh)] overflow-y-scroll no-scrollbar">
        <div className="rounded w-full">
          {/* <div className="text-left px-[20px] border-b-[1px] py-6">
            <h1>Module Completion</h1>
          </div> */}
          {shimmer !== true ? (
            <>
              <div className=" flex flex-row flex-wrap justify-start">
                {certificateState &&
                  certificateState?.map((item) =>
                    item.category === "Module" ? (
                      <div className="rounded-[16px] border-[#DDDDEC] border-[1px] w-full md:w-[42%] lg-[40%] xl:w-[45%] md:mx-[20px] my-[20px] bg-[#fff]">
                        <div className="p-8 flex flex-row justify-between md:h-[21vh] h-auto">
                          <div className="text-left w-[80%]">
                            <p className="text-start text-[#A5A8BB] lg:text-[14px] text-[13px] font-light">
                              Module {item.moduleID}
                            </p>
                            <p className="text-[#4D505C] lg;text-[16px] text-[15px] font-normal">
                              {item.moduleTitle}
                            </p>
                          </div>

                          <img
                            src="../Images/certificate-icon.png"
                            alt="Module Icon"
                            className="w-[70px] h-[70px]"
                          />
                        </div>
                        {item.isDownloadAvailable &&
                        item.isDownloadAvailable !== "YES" ? (
                          <>
                            <div className="flex justify-center  border-t-[1px] border-[#DDDDEC] hover:bg-[#F7F7FF] rounded-b-[16px]">
                              <Link
                                className="hovers:bg-[#303A70] hovesr:bg-gradient-to-r hovers:from-[#303A70] hovers:to-[#121A43] hovers:text-[#fff]  py-2 px-4 justify-center text-[16px] w-full rounded-bl-[16px] rounded-br-[16px] py-3 text-[#3C57AD] hover:font-medium"
                                target="_blank"
                                to={item.appCertificatePath}
                              >
                                {getTranslateText?.data?.length > 0
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "btn_generate"
                                    )[0].keyValue
                                  : "Generate Certificate"}
                              </Link>{" "}
                            </div>
                            {/* Generate Certificate Button  */}{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            {/* Generate Certificate Button End */}
                            <div className="flex justify-center  border-t-[1px] border-[#DDDDEC] ">
                              <div className="flex cursor-pointer hover:bg-[#F7F7FF] [&_p]:hover:text-semibold items-center px-4 py-2  text-[16px] w-[100%] justify-center border-r-[0.5px] border-[#DDDDEC] rounded-bl-[16px] py-3 hover:font-medium">
                                {/* <img alt="view" src={view} /> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19.582"
                                  height="13.054"
                                  viewBox="0 0 19.582 13.054"
                                  className="w-[15px]"
                                >
                                  <path
                                    id="Icon_awesome-eye"
                                    data-name="Icon awesome-eye"
                                    d="M19.463,10.531A10.9,10.9,0,0,0,9.791,4.5,10.905,10.905,0,0,0,.118,10.531a1.1,1.1,0,0,0,0,.992,10.9,10.9,0,0,0,9.672,6.031,10.905,10.905,0,0,0,9.672-6.031A1.1,1.1,0,0,0,19.463,10.531ZM9.791,15.923a4.9,4.9,0,1,1,4.9-4.9A4.9,4.9,0,0,1,9.791,15.923Zm0-8.159a3.24,3.24,0,0,0-.86.129,1.627,1.627,0,0,1-2.274,2.274,3.256,3.256,0,1,0,3.135-2.4Z"
                                    transform="translate(0 -4.5)"
                                    fill="#3c57ad"
                                  />
                                </svg>
                                {/* */}
                                <a href={item.certificatePath} target="_blank">
                                  <p className="text-[#3C57AD] ml-2">
                                    {getTranslateText?.data?.length > 0
                                      ? getTranslateText?.data?.filter(
                                          (item) => item.pageKey === "btn_view"
                                        )[0].keyValue
                                      : "View"}
                                  </p>
                                </a>
                              </div>
                              <div className="flex cursor-pointer hover:bg-[#F7F7FF] [&_p]:hover:text-semibold items-center px-4 py-1  text-[16px] w-[100%] justify-center border-[#DDDDEC] rounded-br-[16px] py-3 hover:font-medium border-l-[0.5px]">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19.851"
                                  height="13.896"
                                  viewBox="0 0 19.851 13.896"
                                  className="w-[15px]"
                                >
                                  <path
                                    id="Icon_awesome-cloud-download-alt"
                                    data-name="Icon awesome-cloud-download-alt"
                                    d="M16.675,8.286A2.981,2.981,0,0,0,13.9,4.235a2.962,2.962,0,0,0-1.653.5A4.963,4.963,0,0,0,2.978,7.213c0,.084,0,.167.006.251a4.468,4.468,0,0,0,1.483,8.682H15.881a3.97,3.97,0,0,0,.794-7.86Zm-4.122,2.751L9.283,14.306a.5.5,0,0,1-.7,0L5.313,11.037a.5.5,0,0,1,.35-.847H7.692V6.716a.5.5,0,0,1,.5-.5H9.677a.5.5,0,0,1,.5.5V10.19H12.2a.5.5,0,0,1,.35.847Z"
                                    transform="translate(0 -2.25)"
                                    fill="#3c57ad"
                                  />
                                </svg>
                                <a href={item.certificatePath}>
                                  {" "}
                                  <p
                                    className="text-[#3C57AD] ml-2"
                                  >
                                    {getTranslateText?.data?.length > 0
                                      ? getTranslateText?.data?.filter(
                                          (item) =>
                                            item.pageKey === "btn_download"
                                        )[0].keyValue
                                      : "Download"}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <></>
                    )
                  )}
              </div>
            </>
          ) : (
            <>
              <ModuleListShimmer />
              <ModuleListShimmer />
              <ModuleListShimmer />
              <ModuleListShimmer />
              <ModuleListShimmer />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ModuleCompletion;

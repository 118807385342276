import React, { useEffect, useState } from "react";
import AdvanceIntroductionPage from "./AdvanceIntroductionPage";
import AdvanceScreeningQA from "./AdvanceScreeningQA";
import AdvanceReport from "./AdvanceReport";
import { useLocation } from "react-router-dom";

function AdvanceScreening() {
  const location = useLocation();
  const [advanceIntroPage, setAdvanceIntroPage] = useState();
  console.log(advanceIntroPage, "testing");
  console.log("testing");
  console.log(location.state, "jflaskdjf");

  useEffect(() => {
    if (location.state.currentScreeningStatus === "Pending") {
      setAdvanceIntroPage(false);
    } else {
      setAdvanceIntroPage(true);
    }
  }, []);

  return (
    <>
      {advanceIntroPage ? (
        <>
          <AdvanceIntroductionPage setAdvanceIntroPage={setAdvanceIntroPage} />
        </>
      ) : (
        <>
          <AdvanceScreeningQA />
        </>
      )}
      {/* <AdvanceIntroductionPage /> */}
      {/* <AdvanceReport /> */}
    </>
  );
}

export default AdvanceScreening;

import { apiEndPoints, http } from "../../utils/http";

const getTranslateText = (id) => {
  return http.get(`${apiEndPoints.GET_APPLICATION_TEXT}/${id}`);
};

const translatedText = {
  getTranslateText,
};
export default translatedText;

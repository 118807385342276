import { useEffect, useState } from "react";

import {
  getInterventionObservation,
  postIntervention,
} from "./InterventionApi";

import Modal from "../../modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";

const InterventionMarking = (props) => {
  const {
    interventionDomainDetails,
    domainCategoryId,
    setOpenMarksInfo,
    setOpenInterventionPlan,
    domainMasterId,
    subDomainName,
    setReadPlanData,
  } = props;
  const [interventionMarking, setInterventionMarking] = useState();
  const userId = JSON.parse(localStorage.getItem("id"));
  const language_id = JSON.parse(localStorage.getItem("l_id"));

  const [checklevel, setChecklevel] = useState([]);
  const [confirmlevelModal, setConfirmlevelModal] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [recallApi, setRecallApi] = useState(false);
  const [checkNumber, setCheckNumber] = useState();
  const [interventionData, setInterventionData] = useState({
    userID: 0,
    studentID: 0,
    domainCategoryID: 0,
    domainMasterID: 0,
    classroomObservationID: 0,
    inputLevelID: 0,
  });
  const [updatedInterventionData, setUpdatedInterventionData] = useState();
  const getTranslateText = useSelector(getTranslatedTextSelector)
  // Filer out marking details //
  const markingdetails = interventionDomainDetails.filter(
    (data) => data.domainCategoryId === domainCategoryId
  );

  const levelsList = [
    {
      id: 1,
      title: "Level 1",
    },
    {
      id: 2,
      title: "Level 2",
    },
    {
      id: 3,
      title: "Level 3",
    },
  ];

  const openCheckConfirm = (index, e, data) => {
    if (e.questionId === e.questionId) {
      setChecked(true);
    }
    setConfirmlevelModal(true);
  
    setCheckNumber(e.id);

    const updatedData = {
      ...interventionData,
      userID: data.userId,
      studentID: data.studentId,
      domainCategoryID: data.domainCategoryID,
      domainMasterID: data.domainMasterID,
      classroomObservationID: data.classroomObservationID,
      inputLevelID: e.id,
    };
    setUpdatedInterventionData(updatedData);

  };

  const getInteventionMarkings = () => {
    getInterventionObservation(
      userId,
      markingdetails[0].studentId,
      markingdetails[0].domainCategoryId,
      domainMasterId,
      language_id
    ).then((response) => {
      if (
        response?.data?.errorCode === "200" &&
        response?.data?.message === "Success"
      ) {
        setRecallApi(false);
        setInterventionMarking(response.data.response);
      } else {
        
      }
    });
  };

  // Post Intervention Api //
  const postInterventionData = () => {

    postIntervention(updatedInterventionData).then((response) => {
      if (
        response.data.errorCode === "200" &&
        response.data.message === "Success"
      ) {
        setRecallApi(true);
        setConfirmlevelModal(false);
        toast.success("Submitted Successfully");
      } else {
      
      }
    });
  };

  const handleCheckboxClick = (index) => {
    let updatedCheckedLevels = [];

    // Check the clicked checkbox and all previous checkboxes
    for (let i = 0; i <= index; i++) {
      updatedCheckedLevels.push(levelsList[i].id);
    }

    setChecklevel(updatedCheckedLevels);
  };
  const submitInterventionMarking = () => {
    postInterventionData();
  };

  useEffect(() => {
    getInteventionMarkings();
  }, [recallApi]);

  return (
    <>
      <div>
        <p className="text-[#848BAA] text-left">
        {
        getTranslateText?.data.length > 0
        ? getTranslateText?.data?.filter(
        (item) => item?.pageKey === "intervention_classroom_observation_top_instruction"
        )[0]?.keyValue
        ? getTranslateText?.data?.filter(
        (item) => item?.pageKey === "intervention_classroom_observation_top_instruction"
        )[0]?.keyValue
        : "Please select a specific concern for the child to access the corresponding intervention plan"
        : "Please select a specific concern for the child to access the corresponding intervention plan"
        }
        </p>
        {markingdetails.map((data) => (
          <div className="flex flex-row justify-between sm:my-2 my-4">
            <h3 className="text-[#101942] text-[16px] font-semibold text-left">
              {subDomainName}
            </h3>
            <h3
              className="text-[#2F43AB] font-medium text-[14px] text-right cursor-pointer"
              onClick={() => {
                // navigate("/user/intervention_levels_info", setMarkingOpen);
                setOpenMarksInfo(true);
              }}
            >
              How to mark levels?
            </h3>
          </div>
        ))}

        <div>
          {interventionMarking?.map((data) => (
            <div className="shadow-[0px_0px_20px_#48456C21] rounded-[20px] mb-6">
              <div className="flex sm:flex-row flex-col justify-between py-4 px-6">
                <p className="text-left w-[80%] text-[#101942] text-[14px] font-normal ">
                  {data.classroomObservationName}
                </p>
                <p
                  className="text-[#2F43AB] text-[14px] font-medium cursor-pointer text-left"
                  onClick={() => {
                    setOpenInterventionPlan(true);
                    setReadPlanData(data);
                  }}
                >
                  Read plan{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: "#2F43AB" }}
                  />
                </p>
              </div>
              <div className="bg-[#F4F8FC] py-2 px-6 flex ">
                {levelsList?.map((e, index) => (
                  <label
                    className="flex flex-row sm:w-3/12 w-6/12 "
                    key={index}
                  >
                    <input
                      type="checkbox"
                      id={data.questionId}
                      step={e.id}
                      // defaultChecked={data.inputLevelID === e.id ? true : false}
                      disabled={data.inputLevelID >= e.id ? true : false}
                      onClick={(event) => {
                        openCheckConfirm(index, e, data);
                        setQuestionId(event.target.id);
                        handleCheckboxClick(index);
                      }}
                      checked={
                        data.inputLevelID !== 0
                          ? data.inputLevelID >= e.id
                          : data.questionId === +questionId
                          ? checklevel.includes(e.id)
                          : false
                      }
                      className="mr-2"
                    />
                    <p>{e.title}</p>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Modal to check level */}
      <Modal visible={confirmlevelModal}>
        <div className="bg-[#fff] rounded-[30px] py-6 px-8 w-9/12 md:w-4/12">
          <div className="flex flex-col items-center">
            <h2 className="text-[#101942] text-[20px] font-semibold mb-2">
              Are you sure?
            </h2>
            {checkNumber <= 2 ? (
              <p className="text-[#101942] text-[14px] text-center font-normal">
                Are you sure your student has achieved Level {checkNumber}? The
                action cannot be changed after submitting 'Yes'.
              </p>
            ) : (
              <p className="text-[#101942] text-[14px] text-center font-normal">
                Are you sure your student has achieved Level 1 and Level 2? The
                action cannot be changed after submitting 'Yes'.
              </p>
            )}
            {/* <p className="text-[#101942] text-[14px] text-center font-normal">
              Are you sure your student has achieved Level 1 and Level 2? The
              action cannot be changed after submitting 'Yes'.
            </p> */}
            <div className="[&_button]:rounded-[10px] lg:[&_button]:px-12 [&_button]:px-8 [&_button]:py-2 mt-4 [&_button]:mx-4 flex flex-row text-[16px]">
              <button
                className="bg-[#E7EAFF] text-[#3C57AD] "
                onClick={() => {
                  setConfirmlevelModal(false);
                  setChecked(false);
                  setChecklevel([]);
                }}
              >
                No
              </button>
              <button
                className="gradient-96 text-[#fff]"
                onClick={submitInterventionMarking}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InterventionMarking;

import axios from "axios";
import { baseURL } from "../../../../utils/http/http-common";

export const CheckStudentAPi = async (body) => {
  try {
    const response = await axios.post(
      `${baseURL}/Screening/CheckStudentDetails`,
      body
    );
    console.log(body, "body for student");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const StartScreeningOne = async (data, base64String) => {
  console.log(data);
  const formData = new FormData();
  formData.append("StudentName", data.studentName);
  formData.append(
    "studentImage",
    data.studentImage ? data.studentImage : base64String
  );
  formData.append("FatherName", data.fatherName);
  formData.append("MotherName", data.motherName);
  formData.append("GradeId", data.gradeId);
  formData.append("SchoolTypeID", data.schoolTypeID);
  formData.append("SchoolBoardID", data.schoolBoardID);
  formData.append("GenderId", data.genderId);
  formData.append("SchoolId", data.schoolId);
  formData.append("DOB", data.dob);
  formData.append("MotherProfessionId", data.motherProfessionId);
  formData.append("FatherProfessionId", data.fatherProfessionId);
  formData.append("ParentsMobileNo", data.parentsMobileNo);
  formData.append("Residence", data.residence);
  formData.append("EducationTypeId", data.educationTypeId);
  formData.append("UserId", data.userId);
  formData.append("Id", 0);
  formData.append("ActiveScreeningCategoryID", data.activeScreeningCategoryID);
  formData.append("StudentPhotoUrl", data.studentPhotoUrl);
  formData.append("MotherMobileNo", data.MotherMobileNo);
  formData.append("FatherMobileNo", data.FatherMobileNo);
  formData.append("MotherWhatsAppNo", data.MotherWhatsAppNo);
  formData.append("FatherWhatsAppNo", data.FatherWhatsAppNo);
  formData.append("ParentsWhatsAppNo", data.ParentsWhatsAppNo);
  formData.append("CreatedDate", data.createdDate);
  formData.append("UpdatedDate", data.updatedDate);
  formData.append("CreatedBy", data.createdBy);
  formData.append("UpdatedBy", data.updatedBy);
  formData.append("Status", data.status);
  formData.append("Priority", data.priority);
  try {
    const response = await axios.post(
      `${baseURL}/Screening/PostStudentDetails`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Assuming you're sending form data
        },
      }
    );
    return response.data;
  } catch (error) {}
};

export const screeningPhaseOneQA = async (gradeId, languageId) => {
  try {
    const response = await axios.get(
      `${baseURL}/QuestionBank/GetActiveQuestions/${languageId}/${gradeId}`
    );
    return response.data;
  } catch (error) {
  
    return error.config.data;
  }
};

export const screeningPhaseOneAnswer = async (body) => {
  console.log(body);
  try {
    const response = await axios.post(
      `${baseURL}/Screening/PostScreening`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const screeningPhaseTwoStudentList = async (
  UserId,
  LanguageId,
  ScreeningCategoryID
) => {
  // console.log(language_id, userId, "api called");
  try {
    const response = await axios.get(
      `${baseURL}/QuestionBank/GetMyCases/${UserId}/${LanguageId}/${ScreeningCategoryID}`
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error Fetching Screeing 2 Student Data : ", error);
    throw error;
  }
};

export const screeningPhaseTwoStudentPendingList = async (
  UserId,
  LanguageId,
  ScreeningCategoryID
) => {

  try {
    const response = await axios.get(
      `${baseURL}/QuestionBank/GetMyCasesForWeb/${UserId}/${LanguageId}/${ScreeningCategoryID}`
    );
    // console.log(response,'response inside')
    return response;
  } catch (error) {
    console.log("Error Fetching Screeing 2 Student Data : ", error);
    // throw error.config.data;
    return error.config.data;
  }
};

export const screeningPhaseCategory = async (languageId) => {
  try {
    const response = await axios.get(
      `${baseURL}/ScreeningCategory/GetScreeningCategory/${languageId}`
    );
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    return error.config.data;
  }
};

export const updateStudentScreeningStatus = async (
  StudentID,
  ScreeningCategoryID
) => {

  try {
    const response = await axios.get(
      `${baseURL}/Screening/UpdateStudentScreeningStatus/${StudentID}/${ScreeningCategoryID}`
    );
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    throw error;
  }
};

export const GetDomainProfilerForm = async (
  StudentID,
  ScreeningCategoryID,
  UserId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Domain/GetDomainProfilerForm/${ScreeningCategoryID}/${StudentID}/${UserId}`
    );
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    throw error;
  }
};

export const getProfilerFormQuestionAnswers = async (
  LanguageId,
  ScreeningCategoryID,
  DomainMasterID
) => {
  try {
    const response = await axios.get(
      `${baseURL}/QuestionBank/GetActiveQuestion/${LanguageId}/${ScreeningCategoryID}/${DomainMasterID}`
    );
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    throw error;
  }
};

export const personalStudentData = async (studentId) => {
  try {
    const response = await axios.get(
      `${baseURL}/Screening/GetStudentDetail/${studentId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    throw error;
  }
};
export const PostScreeningProfilerForm = async (data) => {
  try {
    const response = await axios.post(
      `${baseURL}/Screening/PostScreeningProfilerForm`,
      data
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 2 Category Data : ", error);
    throw error;
  }
};

export const getAdvanceScreeningDomain = async (
  StudentID,
  ScreeningCategoryID,
  LanguageId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Screening/GetAdvanceChecklistDomain/${ScreeningCategoryID}/${LanguageId}/${StudentID}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 3 Domain Data : ", error);
    throw error;
  }
};

export const getAdvanceScreeningQnA = async (
  LanguageId,
  ScreeningCategoryID,
  DomainMasterID,
  GradeId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/QuestionBank/GetActiveQuestionAdvanceScreening/${LanguageId}/${ScreeningCategoryID}/${DomainMasterID}/${GradeId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 3 Questions : ", error);
    throw error;
  }
};

export const postAdvanceScreeningData = async (data) => {
  try {
    const response = await axios.post(
      `${baseURL}/Screening/PostScreeningAdvanceScreening`,
      data
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Posting Screeing 3 Data : ", error);
    throw error;
  }
};

export const postAdvanceScreeningResult = async (
  StudentId,
  UserId,
  LanguageId
) => {
  try {
    const response = await axios.post(
      `${baseURL}/Screening/ResultAdvanceScreening?StudentId=${StudentId}&UserID=${UserId}&LanguageID=${LanguageId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screeing 3 Result Data : ", error);
    throw error;
  }
};

// Api for profile Classroom Observation
export const classroomObservation = async (StudentId, LanguageId) => {
  try {
    const response = await axios.get(
      `${baseURL}/Screening/GetClassroomObservation/${StudentId}/${LanguageId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Classroom Observation Data : ", error);
    throw error;
  }
};

// Api for profile Report of Profiler Form
export const profilerFormReport = async (
  ScreeningCategoryID,
  LanguageId,
  StudentId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Screening/GetScreeningProfilerFormDetails/${ScreeningCategoryID}/${LanguageId}/${StudentId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Profiler Form Report Data : ", error);
    throw error;
  }
};

// Api for profile Report of Profiler Form
export const Screening1Reports = async (
  ScreeningCategoryID,
  LanguageId,
  StudentId
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Screening/GetScreeningDetails/${ScreeningCategoryID}/${LanguageId}/${StudentId}`
    );
    console.log(response);
    return response?.data?.response;
  } catch (error) {
    console.log("Error Getting Screening 1 Form Report Data : ", error);
    throw error;
  }
};

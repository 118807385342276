import React, { useEffect, useState } from "react";
import {
  getTranslatedText,
  getTranslatedTextSelector,
} from "../../../redux/slices/translatedText/getTranslatedText";
import {
  languageSelector,
  retrieveLanguages,
} from "../../../redux/slices/language/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Dashboard/DashboardLanguage.css";
import backIcon from "../../../assets/images/back-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function DashboardLanguage() {
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const languageData = useSelector(languageSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [languageId, setLanguageId] = useState(localStorage.getItem("l_id"));

  const getLanguages = async () => {
    try {
      const dispatchLanguage = await dispatch(retrieveLanguages()).unwrap();
      return dispatchLanguage;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const getText = async () => {
    await dispatch(getTranslatedText(languageId)).unwrap();
  };

  const getTextTranslated = useSelector(getTranslatedTextSelector);

  useEffect(() => {
    if (languageId) {
      getText();
    }
  }, [languageId]);

  // Filter Languages //
  // const newLanguageData = languageData.data.slice(0, -2);
  const newLanguageData = languageData?.data?.filter(
    (data) => data.status === 1
  );

  return (
    <>
      <div>
        <div className="flex mt-5 ml-5">
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ color: "#000000" }}
            size="xl"
            onClick={() => navigate(-1)}
            className="mr-5 cursor-pointer"
          />
        </div>
        <div className=" 2xl:mx-[400px] xl:my-[100px] xl:mx-[300px] lg:mx-[200px] lg:my-[100px] md:mx-[100px] md:my-[50px] my-[100px]">
          <h1 className="lg:text-[30px] text-[26px] font-medium mt-[0px] mb-2">
            {getTextTranslated?.data?.length > 0
              ? getTextTranslated?.data?.filter(
                  (item) => item?.pageKey === "language_choose"
                )[0]?.keyValue
                ? getTextTranslated?.data?.filter(
                    (item) => item?.pageKey === "language_choose"
                  )[0]?.keyValue
                : "Choose your language"
              : "Choose your language"}
          </h1>
          {/* <div className="flex flex-wrap flex-row items-center justify-center h-[calc(100vh-65vh)] lg:h-[calc(100vh-58vh)]  max-h-[58vh] overflow-y-scroll"> */}
          <div className="flex flex-wrap flex-row items-center justify-center ">
            {newLanguageData &&
              newLanguageData?.map((item, index) => (
                <div
                  className={`dashboardlangugage-box  ${
                    item.id == localStorage.getItem("l_id") ? (
                      "bg-[#101942]"
                    ) : (
                      <></>
                    )
                  }`}
                  onClick={() => {
                    localStorage.setItem("l_id", item.id);
                    setLanguageId(item.id);
                    navigate("/");
                  }}
                >
                  <div>
                    <img
                      className="w-[30px] aspect-[3/2] object-contain mb-3"
                      src={item.langIcon}
                    />
                  </div>
                  <p
                    className={`${
                      item.id == localStorage.getItem("l_id") ? (
                        "text-[#fff]"
                      ) : (
                        <></>
                      )
                    }`}
                  >
                    {item.translatedName}
                  </p>
                </div>
              ))}
          </div>
          <button
            type="submit"
            className="bg-[#101942] text-[#fff] py-3 md:py-2  rounded-md m-auto flex justify-center relative mt-2 w-[70%] md:w-[70%] lg:w-[70%] hidden"
            disabled={localStorage.getItem("l_id") === null ? true : false}
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="whitespace-nowrap">
              {getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "btn_proceed"
                  )[0].keyValue
                : "Proceed"}
            </div>
            {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
            <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
              <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default DashboardLanguage;

import React, { useEffect, useState } from "react";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import {
  checkProfile,
  dontAskAgainInstruction,
  getInterventionStudent,
} from "./InterventionApi";
import bookImg from "../../../assets/images/book.svg";
import noData from "../../../assets/images/no-data.svg";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "react-step-progress-bar";
import Modal from "../../modal/Modal";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const Intervention = () => {
  const [instructions, setInstruction] = useState();
  const [loader, setLoader] = useState();
  const [showInstruction, setShowInstruction] = useState(true);
  const [currentTab, setCurrentTab] = useState("1");
  const [tabContent, setTabContent] = useState("Pending");
  const [interventionStudents, setInterventionStudents] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const language_id = JSON.parse(localStorage.getItem("l_id"));
  const userId = JSON.parse(localStorage.getItem("id"));
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const navigate = useNavigate();
  const parse = require("html-react-parser");
  const [checkInstruction, setCheckInstruction] = useState([]);
  const [dontShowInstruction, setDontShowInstruction] = useState("false");
  const [instructionModal, setInstructionModal] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [serverErrorModal , setServerErrorModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState();

  const interventionTabs = [
    {
      id: "1",
      title:
        getTranslateText?.data?.length > 0
          ? getTranslateText?.data?.filter(
              (item) => item?.pageKey === "label_pending"
            )[0]?.keyValue
            ? getTranslateText?.data?.filter(
                (item) => item?.pageKey === "label_pending"
              )[0]?.keyValue
            : "Pending"
          : "Pending",
    },
    {
      id: "2",
      title:
        getTranslateText?.data?.length > 0
          ? getTranslateText?.data?.filter(
              (item) => item?.pageKey === "key_inprogress"
            )[0]?.keyValue
            ? getTranslateText?.data?.filter(
                (item) => item?.pageKey === "key_inprogress"
              )[0]?.keyValue
            : "Inprogress"
          : "Inprogress",
    },
    {
      id: "3",
      title:
        getTranslateText?.data?.length > 0
          ? getTranslateText?.data?.filter(
              (item) => item?.pageKey === "key_completed"
            )[0]?.keyValue
            ? getTranslateText?.data?.filter(
                (item) => item?.pageKey === "key_completed"
              )[0]?.keyValue
            : "Completed"
          : "Completed",
    },
  ];

  const getInstruction = (l_id) => {
    fetchInstruction("Intervention Start Instruction", l_id).then(
      (response) => {
        if (response.response[0] === undefined) {
          getInstruction(1);
        } else {
          setInstruction(response.response[0]);

          setLoader(false);
        }
      }
    );
  };

  // Dont Ask Again Instruction Api calling //
  const interventionDontAskAgain = (userId) => {
    dontAskAgainInstruction(userId, 7).then((response) => {
      return response;
    });
  };

  // Check profile api for instruction //
  const checkProfileInstruction = () => {
    checkProfile(userId).then((response) => {

      setCheckInstruction(response?.data?.response?.instructionData);
      // return response;
    });
  };
  // Filter out intervention profile data //
  const interventionProfle = checkInstruction?.filter(
    (data) => data?.instructionID === 7
  );

  const studentIntervention = () => {
    
    getInterventionStudent(userId).then((response) => {
      if(!response){
        setServerErrorModal(true);
        return;
      }

      setInterventionStudents(response.data.response);
      const data = response.data.response?.filter(
        (data) => data.interventionStatus === "Pending"
      );
     
      setFilterData(data);
    });
  };

  // Close instruction //
  const closeInstruction = () => {
    if (dontShowInstruction === true) {
      interventionDontAskAgain(userId);
      setShowInstruction(false);
    } else {
      setShowInstruction(false);
    }
  };

  // Handle tab click //
  const handleTabClick = (e) => {
    setCurrentTab(e?.target?.id);
    const tab = e?.target?.id;

    setSelectedTab(tab);

    if (tab === "1") {
      const data = interventionStudents?.filter(
        (data) => data.interventionStatus === "Pending"
      );
      setFilterData(data);
      setTabContent("Pending");
    } else if (tab === "2") {
      const data = interventionStudents?.filter(
        (data) => data.interventionStatus === "Inprogress"
      );
      setTabContent("Inprogress");
      setFilterData(data);
    } else {
      setTabContent("Completed");
      const data = interventionStudents?.filter(
        (data) => data.interventionStatus === "Completed"
      );
      setFilterData(data);
    }
  };

  useEffect(() => {
    getInstruction(language_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language_id]);

  useEffect(() => {
    studentIntervention();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    checkProfileInstruction(userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check course completion //
  const advanceScreeningCompleted = localStorage.getItem("advanceCompleted");

  return (
    <>
      {advanceScreeningCompleted === "0" ? (
        <>
          {/* Modal */}
          <Modal
            onClick={() => setShowModal(false)}
            onClose={() => setShowModal(true)}
            visible={showModal}
          >
            <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
              <h2>
                Sorry
                <br /> This Feature is locked. Please complete Advance Screening
                to unlock this feature
              </h2>
              <button
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                Close
              </button>
            </div>
          </Modal>
          ;
        </>
      ) : (
        <>
          {interventionProfle && interventionProfle?.length > 0 &&
          interventionProfle[0]?.isDontAsk === 0 &&
          showInstruction === true ? (
            <div className="bg-[#F4F4FA] h-[calc(100%_-_8%)] pt-8">
              <div className=" md:w-4/6 w-5/6 m-auto ">
                <div className="gradient-96 px-4 py-2 rounded-t-[30px]">
                  <h1 className="text-[#fff] text-[22px] text-semibold text-left ">
                    Intervention
                  </h1>
                </div>
                <div className="bg-[#fff] rounded-b-[30px] p-4">
                  <p>
                    {instructions?.description &&
                      parse(instructions?.description)}
                  </p>
                  <div className="mt-4">
                    <label className="flex justify-start mb-4">
                      <input
                        type="checkbox"
                        onClick={() => {
                          setDontShowInstruction(true);
                        }}
                      />
                      <p className="text-[#101942] font-semibold text-[16px]">
                        Don't show the instruction again
                      </p>
                    </label>
                    <button
                      className="gradient-96 text-center text-[#fff] px-12 py-2 rounded-[10px]"
                      onClick={closeInstruction}
                    >
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "btn_proceed"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "btn_proceed"
                            )[0]?.keyValue
                          : "Proceed"
                        : "Proceed"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="bg-[#F4F4FA] p-4 md:p-8 h-[calc(100%_-_8%)]">
                <div className="flex justify-between mb-4 px-4">
                  <div className="flex">
                    <h2 className="text-[20px] text-[#101942] font-bold">
                      Intervention
                    </h2>
                    <p className="bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[23px] text-[16px]  text-white py-[2px] px-[15px] ml-3">
                      {interventionStudents?.length}
                    </p>
                  </div>

                  <img
                    src={bookImg}
                    className="w-[20px] cursor-pointer"
                    onClick={() => {
                      setInstructionModal(true);
                    }}
                    alt=""
                  />
                </div>
                <div className="bg-[#fff] rounded-[30px] h-[calc(100%_-_8%)]">
                  <div className="flex px-8 pt-4 flex-wrap">
                    {interventionTabs?.map((tabs, i) => (
                      <button
                        // disabled={tabs.id === 4}
                        key={i}
                        id={tabs.id}
                        onClick={handleTabClick}
                        className={`${
                          currentTab === tabs.id
                            ? "gradient-96 text-[#fff]"
                            : " bg-[#E3E3EF] text-[#10194280]"
                        }  px-8 py-[7px] rounded-[25px] mx-[10px] my-2 text-[14px] lg:w-[30%] w-[100%]`}
                      >
                        {tabs.title}
                      </button>
                    ))}
                  </div>
                  <div className="lg:p-[15px] md:p-[30px] p-[15px] flex flex-row flex-wrap overflow-y-scroll md:h-[calc(100vh-33vh)] h-[calc(100vh-35vh)] h=[100vh] no-scrollbar p">
                    {interventionStudents?.length === 0 ? (
                      ''
                    ) : (
                      <>
                        {filterData?.length === 0 ? (
                          <div className="flex flex-col justify-center w-full items-center">
                            <img
                              src="../Images/screen1.svg"
                              alt="Screening 1"
                              className="lg:w-[30%] lg:mb-[30px]"
                            />
                            <h1 className="text-lg font-semibold">
                              {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "key_no_data"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "key_no_data"
                            )[0]?.keyValue
                          : "No Data Found!"
                        : "No Data Found!"}
                        </h1>
                        <h3 className="mt-2 text-sm">
                        {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "label_no_data"
                          )[0]?.keyValue
                          ?getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "label_no_data"
                          )[0]?.keyValue
                          : "No Case found !!"
                        : "No Case found !!"}
                              
                        </h3>
                          </div>
                        ) : (
                          <>
                            {filterData?.map((data) => (
                              <>
                                <div className="rounded-[12px] bg-[#fff] xl:w-[44%] lg:w-[44%] w-[100%] lg:mx-[20px] my-[15px] shadow-[0px_0px_20px_#48456C21] h-fit">
                                  <div className="flex flex-row items-center mx-[20px] mb-[20px] pt-[20px]">
                                    <img
                                      // src="../Images/student-screening-pic.png"
                                      src={data.studentPhotoUrl}
                                      alt=""
                                      className="border-[#fa2229] rounded-[100%] border-2 w-[70px] h-[70px] "
                                    />
                                    <div className="flex flex-row justify-between w-full">
                                      <div className="flex flex-col text-left pl-[15px]">
                                        <p className="mb-[5px] font-medium">
                                          {data.studentName}
                                        </p>
                                        <p className="text-[#848BAA] text-[12px]">
                                          {data.gradeName}
                                        </p>
                                      </div>

                                      <p
                                        className="text-[#3C57AD] text-[14px] cursor-pointer"
                                        onClick={() => {
                                          navigate(
                                            "/user/intervention-student-profile",
                                            {
                                              state: {
                                                data: {
                                                  ...data,
                                                  activeScreeningCategoryID: 3,
                                                  currentScreeningStatus:
                                                    "Done",
                                                  setInterventionProfile: true,
                                                },
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        {getTranslateText?.data?.length > 0
                                          ? getTranslateText?.data?.filter(
                                              (item) =>
                                                item.pageKey ===
                                                "key_view_profiler"
                                            )[0]?.keyValue
                                            ? getTranslateText?.data?.filter(
                                                (item) =>
                                                  item?.pageKey ===
                                                  "key_view_profiler"
                                              )[0]?.keyValue
                                            : "View profiler"
                                          : "View profiler"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between bg-[#F4F8FC] px-[30px] py-[10px]">
                                    <div className="text-[#101942] text-[16px] text-left ">
                                      {data.interventionStatus === "Pending" ? (
                                        <>
                                          {getTranslateText?.data?.length > 0
                                            ? getTranslateText?.data?.filter(
                                                (item) =>
                                                  item.pageKey ===
                                                  "key_start_intervention"
                                              )[0]?.keyValue
                                              ? getTranslateText?.data?.filter(
                                                  (item) =>
                                                    item.pageKey ===
                                                    "key_start_intervention"
                                                )[0]?.keyValue
                                              : "Start Intervention"
                                            : "Start Intervention"}
                                        </>
                                      ) : data.interventionStatus ===
                                        "Inprogress" ? (
                                        <>
                                          <div>
                                            <div className="flex flex-row justify-between mb-1">
                                              <h4 className="text-[#101942] text-[12px] font-normal">
                                                Progress achieved
                                              </h4>
                                              <p className="text-[#3146B2] text-[12px] font-semibold">
                                                {data.progressStudentWise}%
                                              </p>
                                            </div>
                                            <ProgressBar
                                              percent={data.progressStudentWise}
                                              filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                                              //   width={`${mobileOn === true ? "70px" : "200px"}`}
                                              width={` 220px`}
                                              //   position={answerSheet.screeningQuestionOption.length}
                                              //   index={answerSheet.screeningQuestionOption.length}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <div>
                                          <div className="flex flex-row justify-between mb-1">
                                            <h4 className="text-[#101942] text-[12px] font-normal">
                                              Progress achieved
                                            </h4>
                                            <p className="text-[#3146B2] text-[12px] font-semibold">
                                              {data.progressStudentWise}%
                                            </p>
                                          </div>
                                          <ProgressBar
                                            percent={data.progressStudentWise}
                                            filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                                            //   width={`${mobileOn === true ? "70px" : "200px"}`}
                                            width={` 220px`}
                                            //   position={answerSheet.screeningQuestionOption.length}
                                            //   index={answerSheet.screeningQuestionOption.length}
                                          />
                                        </div>
                                      )}{" "}
                                    </div>
                                    <img
                                      alt="arrow"
                                      src="../Images/arrow-icon.svg"
                                      className={`w-[30px] cursor-pointer `}
                                      onClick={() => {
                                        navigate("/user/intervention_domains", {
                                          state: {
                                            data,
                                            instructions,
                                            selectedTab,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </>
                    )}
                   
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Instruction Modal */}
          <Modal visible={instructionModal}>
            {/* <div className="bg-[#fff] rounded-[30px] py-6 px-8 w-5/12"> */}
            <div className="bg-[#fff] rounded-[30px] w-5/12">
              <div className="flex flex-col items-center">
               <div className='w-full gradient-96 rounded-t-[28px] flex justify-between items-center px-6'>
                <p className="text-[#ffffff] text-[20px] font-semibold my-4">
                  {instructions?.defaultName}
                </p>
                <p
            className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
            onClick={() => setInstructionModal(false)}
            >
            <FontAwesomeIcon
            icon={faXmark}
            size="md"
            style={{ color: "#3c3f4f" }}
            />
            </p>
               </div>
                <p className="text-[#101942] text-[14px] text-center font-normal my-6 mx-5">
                  {instructions?.description &&
                    parse(instructions?.description)}
                </p>
              </div>
            </div>
          </Modal>
        </>
      )}
      <ServerUpgradtionModal show={serverErrorModal} setShow={setServerErrorModal} apiCall={studentIntervention} />
    </>
  );
};

export default Intervention;

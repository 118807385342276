import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import ModuleCompletion from "./ModuleCompletion";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";

import CourseCompletion from "./CourseCompletion";
import axios from "axios";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const Certificate = () => {
  const [certificateState, setCertificateState] = useState([]);
  const [shimmer, setShimmer] = useState(true);
  const [tab, setTab] = useState(1);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [showbutton, setShowButton] = useState(false);
  const [serverErrorModal , setServerErrorModal] = useState(false)

  const tabsList = [
    {
      id: 1,
      title: (
        <>
          {getTranslateText?.data?.length > 0
            ? getTranslateText?.data?.filter(
                (item) => item.pageKey === "key_module"
              )[0]?.keyValue
            : "Module"}
        </>
      ),
      // title: "Module",

      content: <ModuleCompletion />,
    },
    {
      id: 2,
      title: (
        <>
          {getTranslateText?.data?.length > 0
            ? getTranslateText?.data?.filter(
                (item) => item.pageKey === "key_course"
              )[0]?.keyValue
            : "Course"}
        </>
      ),
      content: <CourseCompletion />,
    },
  ];

  const getCourseList = async () => {
    const userId = JSON.parse(localStorage.getItem("user"));
    const payLoad = {
      partnerID: "2",
      piUserID: userId.id,
    };
    
      await axios
        .post(
          "https://lmsapi.projectinclusion.in/api/Certificate/GetLMSUserCertificate",
          payLoad,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            setCertificateState(response?.data?.response);
            setShimmer(false);
          }
        }).catch((error)=>{
          setServerErrorModal(true)
          return;
        })
  };

  // Handle Tab CLick //
  const handleTabsClick = (e) => {
    const convertToNumber = Number.parseInt(e.target.id);
    setTab(convertToNumber);
  };
  // Handle Tab CLick //

  useEffect(() => {
    getCourseList();
  }, [showbutton]);

  console.log(shimmer,'shimmer')
  return (
    <>
      <div className="bg-[#F4F4FA] md:py-[20px] py-[10px] px-[20px] xl:px-[100px] lg:px-[50px] h-[100Vh] h-[calc(100%_-_9%)] overflow-y-scroll no-scrollbar">
        {/* Tabs */}
        <div className="flex flex-row justify-center md:justify-start md:my-[40px] my-[20px]">
          {tabsList?.map((tabs, i) => (
            <button
              key={i}
              id={tabs.id}
              onClick={handleTabsClick}
              className={`${
                tab === tabs.id
                  ? "bg-[#303A70] bg-gradient-to-r from-[#303A70] to-[#121A43] !text-white "
                  : "bg-[#E3E3EF] text-[#10194280]"
              } px-[45px] py-[8px] rounded-[25px] text-[16px] mr-[20px] md:mr-[40px]`}
            >
              {tabs.title}
            </button>
          ))}
        </div>
        <div className="rounded-[30px] md:bg-[#FFFFFF] md:p-[20px] md:h-[calc(100%_-_20%)] no-scrollbar">
          {tabsList?.map((tabs, i) => (
            // <div key={i}>
            <>
              {tab === tabs.id && (
                <>
                  {!shimmer && certificateState?.length === 0 ? (
                    <div className="flex flex-col justify-center items-center md:bg-[#fff] h-[100%]">
                      <img
                        src="../Images/not-found.svg"
                        alt="Courses Completion Not Found"
                        className="w-[30%] m-auto h-auto m-0"
                      />
                      <div className="flex flex-col items-center justify-center w-[80%]">
                        <h4 className="text-[#4D505C] font-medium text-[16px] mb-[10px]">
                          There is no module certificate yets!
                        </h4>
                      </div>{" "}
                    </div>
                  ) : (
                    <>
                      <div>
                        {(tabs.id === 1 && (
                          <ModuleCompletion
                            certificateState={certificateState}
                            shimmer={shimmer}
                            setShowButton={setShowButton}
                          />
                        )) ||
                          (tabs.id === 2 && (
                            <CourseCompletion
                              certificateState={certificateState}
                              shimmer={shimmer}
                              setShowButton={setShowButton}
                            />
                          ))}
                      </div>
                    </>
                  )}
                </>
              )}
              {/* </div> */}
            </>
          ))}
        </div>
      </div>
      <ServerUpgradtionModal show={serverErrorModal} setShow={setServerErrorModal} apiCall={getCourseList} />
    </>
  );
};

export default Certificate;

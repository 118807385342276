import {
  languageReducer,
  userTypeReducer,
  profileReducer,
  authReducer,
  courseReducer,
  translatedTextReducer,
  certificateReducer,
  screeningReducer,
} from "./slices";

export const rootReducer = {
  languageReducer,
  userTypeReducer,
  profileReducer,
  authReducer,
  courseReducer,
  translatedTextReducer,
  certificateReducer,
  screeningReducer,
};

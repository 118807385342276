import { apiEndPoints, http } from "../../utils/http";

const getOccupation = (languageId) => {
  return http.get(`${apiEndPoints.GET_OCCUPATION}/${languageId}`);
};

const getBoard = (languageId) => {
  return http.get(`${apiEndPoints.GET_BOARD}/${languageId}`);
};

const getSchoolType = (languageId) => {
  return http.get(`${apiEndPoints.GET_SCHOOL_TYPE}/${languageId}`);
};

const getEducationStatus = (languageId) => {
  return http.get(`${apiEndPoints.GET_EDUCATIONALSTATUS}/${languageId}`);
};

const screening = {
  getOccupation,
  getSchoolType,
  getBoard,
  getEducationStatus,
};
export default screening;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  retrieveUserTypes,
} from "../../../redux/slices/userType/userTypeSlice";

import "./UserType.css";

import professionalPic from "../../../assets/images/professionals.svg";
import reviewerPic from "../../../assets/images/reviewer-icon.png";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const UserTypeScreen = (props) => {
  const { handleChange, getTextTranslated } = props;
  const dispatch = useDispatch();

  const [activeUserTypes, setActiveUserTypes] = useState();
  const [serverError , setServerError] = useState(false)

  const getUserTypes = async () => {
    try {
      const dispatchUserTypes = await dispatch(retrieveUserTypes()).unwrap();
      if(!dispatchUserTypes){
        setServerError(true);
        return ;
      }
      setActiveUserTypes(dispatchUserTypes.response);
      return dispatchUserTypes;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserTypes();

    const clearLocalStorageExcpeptOneKey = () => {
      const valueToKeep = localStorage.getItem("l_id");
      localStorage.clear();
      localStorage.setItem("l_id", valueToKeep);
    };
    // localStorage.clear();
    clearLocalStorageExcpeptOneKey("l_id");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <h1 className="text-[26px] lg:text-[30px] font-medium mt-[0px]">
        {getTextTranslated?.data?.length > 0
          ? getTextTranslated?.data?.filter(
              (item) => item.pageKey === "please_select_txt"
            )[0]?.keyValue
          : " Please select your Role"}
      </h1>
      <div className="lg:my-12 my-6 flex justify-center flex-wrap md:flex-nowrap  w-full  sm:w-[80%] 2xl:w-[80%] m-auto">
        {activeUserTypes?.map((userType) => (
          // {userTypes?.map((userType) => (
          <div
            key={userType.id}
            className="mx-3 md:w-1/8 lg:w-[25%s] lg:w-auto w-[40%] md:w-[20%] my-[10px] lg:my-0 flex"
          >
            <label className="mr-2 w-full flex flex-col items-center">
              <label htmlFor={userType.id} className="relative">
                <input
                  className="profile-check mr-2"
                  type={"radio"}
                  name="userType"
                  id={userType.id}
                  onChange={(e) => handleChange(e, "userType")}
                  required
                />
                {userType.name === "PARENT" ? (
                  <div className="profile-type-img">
                    <img src="../Images/parents-2.svg" alt="" />
                  </div>
                ) : userType.id === 3 ? (
                  <div className="profile-type-img">
                    <img src="../Images/teacher-icon.svg" alt="" />
                  </div>
                ) : userType.id === 7 ? (
                  <div className="profile-type-img">
                    <img src="../Images/special-educator-icon.svg" alt="" />
                  </div>
                ) : userType.id === 8 ? (
                  <div className="profile-type-img">
                    <img src={professionalPic} alt="" />
                    {/* <img src="../Images/special-educator-icon.svg" alt="" /> */}
                  </div>
                ) : userType.id === 9 ? (
                  <div className="profile-type-img">
                    <img src={reviewerPic} alt="" />
                    {/* <img src="../Images/special-educator-icon.svg" alt="" /> */}
                  </div>
                ) : (
                  <></>
                )}
              </label>
              {/* <img src={userType.icon} alt={""} /> */}
              <p className="text-[#101942] mt-5 font-semibold md:text-[14px]">
                {userType.name === "PARENT"
                  ? getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "key_parent"
                      )[0]?.keyValue
                    : userType.name
                  : userType.name === "TEACHER"
                  ? getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "key_teacher"
                      )[0]?.keyValue
                    : userType.name
                  : userType.name === "SPECIAL EDUCATOR"
                  ? getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "key_special_educator"
                      )[0]?.keyValue
                    : userType.name
                  : userType.name === "REVIEWER"
                  ? getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "key_reviewer"
                      )[0]?.keyValue
                    : userType.name
                  : userType.name === "PROFESSIONALS"
                  ? getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "key_professional"
                      )[0]?.keyValue
                    : userType.name
                  : userType.name}
              </p>
            </label>
          </div>
        ))}
      </div>
      <ServerUpgradtionModal show={serverError} setShow={setServerError} apiCall={getUserTypes} />
    </>
  );
};

export default UserTypeScreen;

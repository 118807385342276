import { useNavigate } from "react-router-dom";
import ReactIcons from "../icons";
const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="flex justify-between p-3 bg-[#101942]">
        {/* <div className="flex text-[#fff]">
          <div className="flex">
            <span>
              <ReactIcons.HiPhone />
            </span>
            <span className="mx-5">+11-26511669</span>
          </div>
          <div className="flex">
            <span>
              <ReactIcons.ImLocation2 />
            </span>
            <span>Delhi India</span>
          </div>
        </div> */}
        {/* <div>
          <button
            className="text-[#fff] bg-amber-400 mx-5 px-4 rounded-md"
            onClick={() => {
              navigate("/user/login");
            }}
          >
            Login
          </button>
        </div> */}
      </header>
    </>
  );
};

export default Header;

import { useEffect, useState, useRef } from "react";
import ReactIcons from "../icons";
import translation from "../../assets/images/translation.png";
import notification from "../../assets/images/notification.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  languageSelector,
  retrieveLanguages,
} from "../../redux/slices/language/languageSlice";
import { getTranslatedText } from "../../redux/slices/translatedText/getTranslatedText";
import { getTranslatedTextSelector } from "../../redux/slices/translatedText/getTranslatedText";

import { getCourseURL } from "../../redux/slices/course/courseSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import profileIcon from "../../assets/images/student-profile-icon.svg";
import Modal from "../../components/modal/Modal";
import OTPInput from "../../components/user/auth/OTPInput";
import { clearBanner } from "../cookiesmanager";

const Header = (props) => {
  const { hideSidebar, setHideSidebar } = props.tsidebar;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [languageID, setLanguageID] = useState(localStorage.getItem("l_id"));
  const [languageName, setLanguageName] = useState("english");
  const languageData = useSelector(languageSelector);
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [showModal, setShowModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const revierwerId = JSON.parse(localStorage.getItem("userTypeId"));
  
  const getLanguages = async () => {
    try {
      const dispatchLanguage = await dispatch(retrieveLanguages()).unwrap();

      return dispatchLanguage;
    } catch (error) {
      console.log(error);
    }
  };
  const getText = async () => {
    await dispatch(getTranslatedText(languageID)).unwrap();
  };
  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    if (languageID) {
      getText();
    }
  }, [languageID]);

  const toggleSidebar = () => {
    setHideSidebar(!hideSidebar);
  };

  // Dropdown function //
  const profileDropdown = () => {
    setOpenDropdown((prevOpenDropdown) => !prevOpenDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      openDropdown &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setOpenDropdown(false);
    }
  };

  // Function to handle click on list items
  const handleListItemClick = () => {
    setOpenDropdown(false); // Close the dropdown when a list item is clicked
  };

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);

  // {getTranslateText?.data?.length > 0
  //   ? getTranslateText?.data?.filter(
  //       (item) => item.pageKey === "language"
  //     )[0]?.keyValue
  //     ? getTranslateText?.data?.filter(
  //         (item) => item.pageKey === "language"
  //       )[0]?.keyValue
  //     : "Language"
  //   : "Language"}
  return (
    <>
      <div className="flex items-center justify-between flex-row md:flex-wrap bg-[#fff]  h-14 border-[#b5b8bf] border-b-[1px]">
        <div className="flex items-center ml-4">
          <ReactIcons.RxHamburgerMenu
            onClick={toggleSidebar}
            className="cursor-pointer w-8 h-8"
          />
          <p className="pl-2.5"></p>
        </div>
        <div className="flex md:flex-wrap items-center">
          <div className="flex lg:mr-[20px] hidden">
            <img alt="" src={translation} className="w-[20%]" />
            <select
              className="bg-transparent cursor-pointer w-1/2 lg:w-1/2"
              value={languageID}
              onChange={(e) => {
                localStorage.setItem("l_id", e.target.value);
                setLanguageID(e.target.value);
                // getUrl(e.target.value);
              }}
            >
              <option disabled value="">
                Select language
              </option>
              {languageData.data?.map((item) => (
                <option
                  key={item.id}
                  onClick={() => {
                    setLanguageID(item.id);
                  }}
                  value={item.id}
                >
                  {item.translatedName}
                </option>
              ))}
            </select>
          </div>
          <div className="md:ml-8 mr-8 md:mr-0 hidden">
            <img alt="" src={notification} className="w-6" />
          </div>

          <div className=" md:mx-4 mx-4 relative">
            <div className="flex md:flex-wrap flex-row items-center">
              <img
                src={
                  JSON.parse(localStorage.getItem("user")).profilePicURL ||
                  profileIcon
                }
                alt="..."
                className="rounded-full w-10 h-10"
              />
              <p className="ml-2 hidden md:block">
                {JSON.parse(localStorage.getItem("user")).firstName}
              </p>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                onClick={profileDropdown}
                // className={`ml-4
                // ${openDropdown ? "rotate-180" : "rotate-0"}
                //  transition-all	`}
                className={`ml-4 transition-all cursor-pointer	`}
              />
            </div>
            <div
              ref={dropdownRef}
              className={`${
                openDropdown ? "block" : "hidden"
              } absolute right-0 mt-[8px] shadow-[0px_5px_15px_#00000059] bg-[#fff] text-left rounded-[10px] z-50 w-[150px]`}
            >
              <ul className="list-none text-[15px] [&_li]:border-t-[1px] [&_li]:border-[#d6d7db] [&_li]:cursor-pointer ">
                <li
                  onClick={() => {
                    navigate("/user/profile");
                    handleListItemClick();
                  }}
                  className="hover:bg-[#f1f1f1] border-[#000] px-4 py-2 rounded-t-[10px] "
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "txt_profile"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "txt_profile"
                        )[0]?.keyValue
                      : "Profile"
                    : "Profile"}
                </li>
                <li
                  className={`${
                    revierwerId === 9 ? "hidden" : ""
                  } "hover:bg-[#f1f1f1] border-[#000] px-4 py-2 "`}
                  onClick={() => {
                    navigate("/user/ChangePassword");
                    handleListItemClick();
                  }}
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "change_password"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "change_password"
                        )[0]?.keyValue
                      : "Change Password"
                    : "Change Password"}
                </li>
                <li
                  className="hover:bg-[#f1f1f1] border-[#000] px-4 py-2 "
                  onClick={() => {
                    navigate("/user/ChangeLanguage");
                    handleListItemClick();
                  }}
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "language"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "language"
                        )[0]?.keyValue
                      : "Language"
                    : "Language"}
                </li>
                {/* <li className="hover:bg-[#f1f1f1] border-[#000] px-4 py-2 ">
                  Delete User Account
                </li> */}
                <li
                  className={`${
                    revierwerId === 9 ? "hidden" : ""
                  } relative group hover:bg-[#f1f1f1] border-[#000] px-4 py-2 relative`}
                >
                  {
                    getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                  (item) => item.pageKey === "item_more"
                  )[0]?.keyValue
                  ? getTranslateText?.data?.filter(
                  (item) => item.pageKey === "item_more"
                 )[0]?.keyValue
                : "More"
                : "More"
                  } {">"}
                  <li
                    className="bg-[#fff] hover:bg-[#f1f1f1] border-[#000] border-[1px] px-4 py-2 absolute right-[100%] top-[-2px] hidden group-hover:block w-[130%] text-red-600 "
                    onClick={() => {
                      navigate("/user/user_account_delete");
                      handleListItemClick();
                      // setDeleteUser(true);
                    }}
                  >
                    {getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_deactivate_acc"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item.pageKey === "key_deactivate_acc"
                          )[0]?.keyValue
                        : "Deactivate Account"
                      : "Deactivate Account"}
                  </li>
                </li>
                <li
                  className="hover:bg-[#f1f1f1] border-[#000] px-4 py-2 rounded-b-[10px] "
                  // onClick={() => {
                  //   navigate("/user/ChangeLanguage");
                  // }}
                  // onClick={() => {
                  //   localStorage.clear();
                  //   navigate("/select/language");
                  // }}
                  onClick={() => {
                    setShowModal(true);
                    handleListItemClick();
                  }}
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "logout"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "logout"
                        )[0]?.keyValue
                      : "Logout"
                    : "Logout"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* // Modal to logout prompt // */}
      <Modal
        visible={showModal}
      >
        <div>
          {/* Modal Content */}
          <div className=" w-12/12 m-auto ">
            <div className="">
              
            </div>
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] flex flex-col justify-center items-center">
              <img src="../Images/sad_puzzle.svg" alt="Puzzle sad Image" />
              <h5 className="font-semibold text-[20px] my-2">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey === "label_logout"
                    )[0]?.keyValue
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_logout"
                      )[0]?.keyValue
                    : "Are you sure, you want to Logout ?"
                  : "Are you sure, you want to Logout ?"}
              </h5>

              <div className="flex justify-around w-[100%]">
                <button
                  onClick={() => {
                    localStorage.clear();
                    clearBanner();
                    navigate("/select/language");
                    setShowModal(false);
                  }}
                  className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "alert_yes"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "alert_yes"
                        )[0]?.keyValue
                      : "Yes"
                    : "Yes"}
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                >
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "alert_no"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "alert_no"
                        )[0]?.keyValue
                      : "No"
                    : "No"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* // Delete User Modal // */}
      <Modal
        // onClick={() => setShowModal(false)}
        // onClose={() => setShowModal(false)}
        visible={deleteUser}
      >
        <div>
          {/* Modal Content */}
          <div className=" w-12/12 m-auto ">
            <div className="bg-[#fff] px-6 py-6 rounded-[30px] ">
              {/* Screen 1 */}
              <div className="flex flex-col justify-center items-center hidden">
                <img
                  src={
                    JSON.parse(localStorage.getItem("user")).profilePicURL ||
                    profileIcon
                  }
                  alt="..."
                  className="rounded-full w-20 h-20"
                />

                <h5 className="font-semibold text-[20px] my-2">
                  Are you sure you want to delete this account?
                </h5>

                <p className="pb-5 text-[14px]">
                  You are requesting to delete all data related to student
                  authentication, quiz proctoring, profile details, bank details
                  and quiz information.
                </p>

                <p className="text-[14px]">
                  You can stop this process by logging back within the next 90
                  days.
                </p>

                <div className="flex justify-around w-[100%]">
                  <button
                    onClick={() => {
                      localStorage.clear();
                      navigate("/select/language");
                      setShowModal(false);
                    }}
                    className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    {getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "key_delete"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "key_delete"
                          )[0]?.keyValue
                        : "Delete"
                      : "Delete"}
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    {getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_cancel"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "label_cancel"
                          )[0]?.keyValue
                        : "Cancel"
                      : "Cancel"}
                  </button>
                </div>
              </div>
              {/* Screen 2 */}
              <div className="flex flex-col justify-center items-center hidden">
                <h5 className="font-semibold text-[20px] my-2">
                  Verify Password
                </h5>

                <input
                  type="text"
                  placeholder="Enter password"
                  className="bg-[#F6F7FA] w-full !outline-none py-2 px-4 rounded-full"
                />

                <div className="flex justify-around w-[100%]">
                  <button
                    onClick={() => {
                      localStorage.clear();
                      navigate("/select/language");
                      setShowModal(false);
                    }}
                    className="bg-gradient-to-r from-[#E7EAFF] to-[#E7EAFF] text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    {getTranslateText?.data?.length > 0
                      ? getTranslateText?.data?.filter(
                          (item) => item.pageKey === "label_cancel"
                        )[0]?.keyValue
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "label_cancel"
                          )[0]?.keyValue
                        : "Cancel"
                      : "Cancel"}
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              {/* Screen 3 */}
              <div className="flex flex-col justify-center items-center">
                <h5 className="font-semibold text-[20px] my-2">Enter OTP</h5>
                <div>
                  <p>OTP has been sent to the number</p>

                  <p>9540518026</p>
                </div>
                <div>
                  <OTPInput
                    autoFocus
                    //isNumberInput
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    // onChangeOTP={
                    //   setOtp &&
                    //   ((otp) => {
                    //     setOtp(otp);
                    //   })
                    // }
                  />
                </div>

                <div className="flex flex-col justify-around w-[100%]">
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center"
                  >
                    Verify OTP
                  </button>
                  <button
                    onClick={() => {
                      localStorage.clear();
                      navigate("/select/language");
                      setShowModal(false);
                    }}
                    className=" text-[#3C57AD] px-[50px] py-[10px] rounded-[10px] mt-[20px] text-center flex flex-row justify-center mr-5"
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;

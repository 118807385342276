import { useState } from "react";
import { userNameRegx } from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import { userNameErrorMessage } from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";

const Login = (props) => {
  const { handleChange, showEnterPass, name, password, getTextTranslated } =
    props;
  const [showPassword, setShowPassord] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const togglePassword = () => {
    setShowPassord(!showPassword);
  };
  
  return (
    <>
      <h1 className="text-[22px] md:text-[32px] font-medium  lg:mb-[20px] xl:mb-[40px]">
        {getTextTranslated?.data?.length > 0
          ? getTextTranslated?.data?.filter(
              (item) => item.pageKey === "log_signup_txt"
            )[0].keyValue
          : "Login or Sign Up"}
      </h1>

      <div className="my-[20px] lg:my-[20px] text-left lg:w-[55%] w-[80%] m-auto">
        <label htmlFor="password" className="text-[16px] mb-0 text-left">
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "phone_or_username"
              )[0].keyValue
            : "Enter Username or Mobile number"}
        </label>
        <div className="bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            className="bg-[#F6F7FA] w-full !outline-none"
            type={"text"}
            minLength={3}
            maxLength={20}
            readOnly={
              JSON.parse(localStorage.getItem("user"))?.userName ? true : false
            }
            value={name}
            placeholder={
              getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "phone_or_username"
                  )[0].keyValue
                : "Enter Username or Mobile number"
            }
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else if (
                !userNameRegx.test(e.target.value) ||
                !e.target.value === " "
              ) {
                handleChange(e, "login");
                setShowErrorMessage(true);
              } else {
                setShowErrorMessage(false);
                handleChange(e, "login");
              }
            }}
            required
          />
        </div>

        {showErrorMessage && <Error message={userNameErrorMessage} />}
      </div>
      {showEnterPass && (
        <>
          <div className="text-left lg:w-[55%] w-[80%] m-auto">
            <label htmlFor="password" className="text-[16px] mb-0 text-left">
              {getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item?.pageKey === "enter_current_password"
                  )[0]?.keyValue
                  ? getTextTranslated?.data?.filter(
                      (item) => item?.pageKey === "enter_current_password"
                    )[0]?.keyValue
                  : "Enter Password"
                : "Enter Password"}
            </label>
            <div className="relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
              <input
                id="password"
                className="bg-[#F6F7FA] w-full !outline-none"
                type={showPassword ? "text" : "password"}
                placeholder={
                  getTextTranslated?.data?.length > 0
                    ? getTextTranslated?.data?.filter(
                        (item) => item?.pageKey === "enter_password_txt"
                      )[0]?.keyValue
                      ? getTextTranslated?.data?.filter(
                          (item) => item?.pageKey === "enter_password_txt"
                        )[0]?.keyValue
                      : "Enter Password"
                    : "Enter Password"
                }
                value={password}
                name="password"
                minLength={6}
                maxLength={20}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === " ") {
                    e.preventDefault();
                  } else {
                    handleChange(e, "loginPass");
                  }
                }}
                required
              />{" "}
              <PasswordToggle
                showPassword={showPassword}
                setShowPassword={togglePassword}
                className="cursor-pointer	"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;

import { useSelector } from "react-redux";
import { getTranslatedTextSelector } from "../../redux/slices/translatedText/getTranslatedText";
import Modal from "../modal/Modal";

const ServerUpgradtionModal = (props) => {
    const {show , setShow , apiCall } = props;

    const getTranslateText = useSelector(getTranslatedTextSelector);

    const handleClick = (event) => {
        setShow(false);
        
        if(apiCall){
        apiCall(event)
       }
    }

    return (
        <Modal visible={show}>
            <div className="bg-[#FFFFFF] rounded-2xl py-6 px-6" >
                <p className="mb-4 text-lg font-semibold">
                {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                (item) => item.pageKey === "key_server_error"
                )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "key_server_error"
                                    )[0]?.keyValue
                                  : "Upgradation in progress"
                        : "Upgradation in progress"
            }
                   
                </p>
                <button type="submit" className="py-3 px-48 gradient-96 rounded-lg text-[#FFFFFF]" onClick={handleClick}>Retry</button>
            </div>
        </Modal>
    )
}

export default ServerUpgradtionModal;
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const ProfileNameInstruction = () => {
  const cases = [
    {
      id: 1,
      fullName: "RAHUL KUMAR",
      firstName: "RAHUL",
      lastName: "KUMAR",
    },
    {
      id: 2,
      fullName: "RAHUL KUMAR GUPTA",
      firstName: "RAHULKUMAR",
      lastName: "GUPTA",
    },
    {
      id: 3,
      fullName: "T N SHASHADRI",
      firstName: "TN",
      lastName: "SHASHADRI",
    },
    {
      id: 4,
      fullName: "TN REKHA VENKATRAMAN",
      firstName: "TN REKHA",
      lastName: "VENKATRAMAN",
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F4F4FA] md:px-24 px-8 py-8 md:h-[calc(100%_-_9%)] h-[calc(100%_-_5%)] overflow-y-scroll">
        <div className="bg-[#fff] rounded-[30px] py-8 px-8">
          <div className="flex">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ color: "#000000" }}
              size="xl"
              onClick={() => navigate(-1)}
              className="mr-5 cursor-pointer"
            />
          </div>
          <div className="text-[#000000] text-[14px] leading-[20px] text-left my-2">
            Your name is an important part of your Profile. Kindly ensure that
            you are filling the right details as per the given format. Remember
            to use this format to separate the first and last names based on the
            spaces in the original names. Make sure to maintain consistency in
            formatting across all your <b>certificates</b> or documents.
          </div>
          <div className="flex flex-row flex-wrap mt-5">
            {cases.map((e) => (
              <div className="md:w-[45%] w-[90%] m-auto mb-4">
                <div className="flex flex-row justify-between bg-[#26378F] text-[#fff] px-3 py-2 text-[13px]">
                  <p>CASE {e.id}</p>
                  <p>{e.fullName}</p>
                </div>
                <div className="text-left my-3 text-[13px]">
                  <p className="text-[14px] mb-1">
                    First Name<span className="text-[#9B0000]">*</span>
                  </p>
                  <p className="bg-[#F8F8F8] px-2 py-2 text-[#000000] font-medium">
                    {e.firstName}
                  </p>
                </div>
                <div className="text-left my-3 text-[13px]">
                  <p className="text-[14px] mb-1">
                    Last Name<span className="text-[#9B0000]">*</span>
                  </p>
                  <p className="bg-[#F8F8F8] px-2 py-2 text-[#000000] font-medium">
                    {e.lastName}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileNameInstruction;

import React, { useState } from "react";
import CryptoJS from "crypto-js";

// Encryption function
export const encrypt = (data) => {
  const key = CryptoJS.enc.Utf8.parse(KEY);
  const iv = CryptoJS.enc.Utf8.parse(INITVECTOR);

  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const base64Encrypted = CryptoJS?.enc?.Base64?.stringify(
    encrypted?.ciphertext
  ); // Encode encrypted data in Base64

  return base64Encrypted;
};

// Decryption function
export const decrypt = (base64Encrypted) => {
  const ciphertext = CryptoJS?.enc?.Base64?.parse(base64Encrypted); // Parse Base64 encoded string
  const key = CryptoJS?.enc?.Utf8?.parse(KEY);
  const iv = CryptoJS?.enc?.Utf8?.parse(INITVECTOR);

  const decrypted = CryptoJS?.AES?.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted?.toString(CryptoJS?.enc?.Utf8);
};

const Encryption = () => {
  const [text, setText] = useState("");
  const [encryptedText, setEncryptedText] = useState("");
  const [decryptedText, setDecryptedText] = useState("");

  const handleEncrypt = () => {
    try {
      const encrypted = encrypt(text);
      setEncryptedText(encrypted);
    } catch (error) {
      console.error("Encryption failed:", error);
    }
  };

  const handleDecrypt = () => {
    try {
      const decrypted = decrypt(encryptedText);
      setDecryptedText(decrypted);
    } catch (error) {
      console.error("Decryption failed:", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter text to encrypt"
      />
      <button onClick={handleEncrypt}>Encrypt</button>
      <p>Encrypted Text (Base64): {encryptedText}</p>
      <button onClick={handleDecrypt}>Decrypt</button>
      <p>Decrypted Text: {decryptedText}</p>
    </div>
  );
};

// Constants for encryption/decryption
const ALGORITHM = "AES";
const TRANSFORMATION = "AES/CBC/PKCS5Padding";
const KEY = "hVmYq3t6w9zB&E)H@McQfTjWnZr4u7x("; // Use the same key on both ends
const INITVECTOR = "0000000000000000"; // Use the same IV on both ends

export default Encryption;

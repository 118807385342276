import React, { useEffect, useState } from "react";
import Card from "../../utils/card/Card";
import {
  languageSelector,
  retrieveLanguages,
} from "../../redux/slices/language/languageSlice";
import "./Language.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getTranslatedText,
  getTranslatedTextSelector,
} from "../../redux/slices/translatedText/getTranslatedText";
import ServerUpgradtionModal from "../ServerUpgradtionModal";

const Language = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageData = useSelector(languageSelector);
  const [languageId, setLanguageId] = useState(localStorage.getItem("l_id"));
  const [serverError , setServerError] = useState(false)
  const getLanguages = async () => {
    try {
      const dispatchLanguage = await dispatch(retrieveLanguages()).unwrap();
      // console.log(dispatchLanguage,'disisiss')
      if(!dispatchLanguage){
        setServerError(true);
      }
      
      return dispatchLanguage;
    } catch (error) {
      console.log(error , 'errroror in the ');
    }
  };

  useEffect(() => {
    getLanguages();

    localStorage.clear();
  }, []);

  const getText = async () => {
    await dispatch(getTranslatedText(languageId)).unwrap();
  };

  const getTextTranslated = useSelector(getTranslatedTextSelector);

  useEffect(() => {
    if (languageId) {
      getText();
    }
  }, [languageId]);

  const newLanguageData = languageData?.data?.filter(
    (data) => data.status === 1
  );



  return (
    <>
    <div>
      <div className="bg-gradient-to-b from-[#002EFF2B] to-[#0DE9FF2B] mx-[20px] my-[40px] md:my-[100px] md:mx-[75px] lg:my-[75px] lg:mx-[150px] xl:mx-[300px] p-4 rounded-md drop-shadow-[0_04px_20px_#00000021]">
        <div className="grid grid-cols-1 md:grid-cols-3 bg-[#FDFDFD] rounded-md p-4 ">
          <div className="col-span-1">
            <Card height={"[50px]"} color="#101942" className="min-h-full" />
          </div>
          <div className="col-span-2 lg:mx-[50px] lg:mt-0 mt-4 lg:mt-0">
            <h1 className="xl:text-[30px] lg:text-[26px] text-[26px] font-medium mt-[0px]">
              {getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item?.pageKey === "language_choose"
                  )[0]?.keyValue
                  ? getTextTranslated?.data?.filter(
                      (item) => item?.pageKey === "language_choose"
                    )[0]?.keyValue
                  : "Choose your language"
                : "Choose your language"}
            </h1>
            <div className="flex flex-wrap flex-row items-center justify-center h-[calc(100vh-65vh)] lg:h-[calc(100vh-58vh)]  max-h-[58vh] overflow-y-scroll">
              {newLanguageData &&
                newLanguageData?.map((item, index) => (
                  <div
                    className={`langugage-box p-[15px] md:p-[10px] lg:p-[20px] ${
                      item.id == localStorage.getItem("l_id") ? (
                        "bg-[#101942]"
                      ) : (
                        <></>
                      )
                    }`}
                    onClick={() => {
                      localStorage.setItem("l_id", item.id);
                      setLanguageId(item.id);
                    }}
                  >
                    <div>
                      <img
                        className="w-[30px] aspect-[3/2] object-contain mb-3"
                        src={item.langIcon}
                      />
                    </div>
                    <p
                      className={`${
                        item.id == localStorage.getItem("l_id") ? (
                          "text-[#fff]"
                        ) : (
                          <></>
                        )
                      }`}
                    >
                      {item.translatedName}
                    </p>
                  </div>
                ))}
            </div>
            <button
              type="submit"
              className="bg-[#101942] text-[#fff] py-3 md:py-2 px-24 rounded-md m-auto flex justify-center relative mt-2 lg:w-[70%] "
              disabled={localStorage.getItem("l_id") === null ? true : false}
              onClick={() => {
                navigate("/user/login");
              }}
            >
              <div className="whitespace-nowrap">
                {getTextTranslated?.data?.length > 0
                  ? getTextTranslated?.data?.filter(
                      (item) => item.pageKey === "btn_proceed"
                    )[0].keyValue
                  : "Proceed"}
              </div>
              {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
              <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
                <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ServerUpgradtionModal show={serverError} setShow={setServerError} apiCall={getLanguages}/>
    </>
  );
};

export default Language;

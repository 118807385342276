import { useState } from "react";
import ReactIcons from "../../../../utils/icons";
import { passwordValidation } from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import { passwordErrorMessage } from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";
const ForgotPassword = (props) => {
  const { handleChange, getTextTranslated } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="my-[20px] lg:my-[20px] text-left lg:w-[55%] w-[80%] m-auto">
        <label htmlFor="enterPassword">
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "enter_password_txt"
              )[0].keyValue
            : "Enter Password"}
        </label>
        <div className="relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            id="Password"
            name="Password"
            className="bg-[#F6F7FA] w-full !outline-none"
            type={showPassword ? "text" : "password"}
            placeholder={
              getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "enter_password_txt"
                  )[0].keyValue
                : "Enter Password"
            }
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else if (
                !passwordValidation.test(e.target.value) ||
                !e.target.value === " "
              ) {
                setShowPasswordError(true);
                handleChange(e, "forgotPassword");
              } else {
                setShowPasswordError(false);
                handleChange(e, "forgotPassword");
              }
            }}
            required
          />
          <PasswordToggle
            showPassword={showPassword}
            setShowPassword={() => togglePassword("password")}
          />
          {showPasswordError && <Error message={passwordErrorMessage} />}
        </div>
      </div>
      <div className="my-[20px] lg:my-[20px] text-left lg:w-[55%] w-[80%] m-auto">
        <label htmlFor="enterPassword">
          {getTextTranslated?.data?.length > 0
            ? getTextTranslated?.data?.filter(
                (item) => item.pageKey === "confirm_pass_txt"
              )[0].keyValue
            : "Confirm password"}
        </label>
        <div className="relative bg-[#F6F7FA] py-2 px-3 pr-10 my-2 border border-[#F6F7FA] rounded-md">
          <input
            id="confirmPassword"
            name="confirmPassword"
            className="bg-[#F6F7FA] w-full !outline-none"
            type={showConfirmPassword ? "text" : "password"}
            placeholder={
              getTextTranslated?.data?.length > 0
                ? getTextTranslated?.data?.filter(
                    (item) => item.pageKey === "confirm_pass_txt"
                  )[0].keyValue
                : "Confirm password"
            }
            onChange={(e) => {
              if (e.target.value === " ") {
                e.preventDefault();
              } else if (
                !passwordValidation.test(e.target.value) ||
                !e.target.value === " "
              ) {
                setConfirmPasswordError(true);
                handleChange(e, "forgotPassword");
              } else {
                setConfirmPasswordError(false);
                handleChange(e, "forgotPassword");
              }
            }}
            required
          />

          <PasswordToggle
            showPassword={showConfirmPassword}
            setShowPassword={() => togglePassword("confirmPassword")}
          />
          {confirmPasswordError && <Error message={passwordErrorMessage} />}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import {
  combineReducers,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import certificate from "../../services/certificate.service";
const initialState = {
  status: "idle",
  data: [],
  error: false,
};

export const certificateType = createAsyncThunk(
  "get/certificate/type",
  async (_, { rejectWithValue }) => {
    try {
      const res = await certificate.getCertificateType();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const certificateSlice = createSlice({
  name: "languages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(certificateType.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(certificateType.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(certificateType.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const certificateTypeSelector = (state) => {
  return state.certificateReducer.certificateSlice;
};

const certificateReducer = combineReducers({
  languageSlice: certificateSlice.reducer,
});
export default certificateReducer;

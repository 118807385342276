import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { fetchInstruction } from "../../../utils/instruction/InstructionApi";
import ScreeningDashboard from "./ScreeningDashboard";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router-dom";

import Loader from "../../../utils/loader/Loader";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const Screening = () => {
  const parse = require("html-react-parser");
  const navigate = useNavigate();
  const language_id = localStorage.getItem("l_id");
  const [state, setState] = useState();
  const [scrDash, setSrcDash] = useState(true);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  const [showModal, setShowModal] = useState(true);
  const [loader, setLoader] = useState(true);
  const [serverErrorModal , setServerErrorModal] = useState(false);

  console.log(getTranslateText, "getTranslateText");

  const getInstruction = (l_id) => {
    fetchInstruction("Screening-1 Disclaimer", language_id).then((response) => {
      console.log(response,'responseeee')
      if(!response){
       setServerErrorModal(true);
       return ;
      }
      if (response.response[0] === undefined) {
        // getInstruction(1);
        setServerErrorModal(false);
      } else {
        setServerErrorModal(false);
        setState(response.response[0]);
        setLoader(false);
      }
    }).catch((error)=>{
      console.log(error,'error in the')
    });
  };

  const content = state?.description;

  useEffect(() => {
    getInstruction(language_id);
  }, [language_id]);
  // console.log(content);
  const courseCompleted = localStorage.getItem("courseCompleted");

  console.log(serverErrorModal,'serverErrorModal')

  return (
    <>
      {courseCompleted === "0" ? (
        <>
          <Modal
            onClick={() => setShowModal(false)}
            onClose={() => setShowModal(true)}
            visible={showModal}
          >
            <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
              <h2>
                Sorry
                <br /> This Feature is locked. Please complete course to unlock
                this feature
              </h2>
              <button
                className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center"
                onClick={() => {
                  navigate("/");
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </>
      ) : (
        <>
          {loader !== true ? (
            <>
              {scrDash ? (
                <div className="bg-[#f4f4fa] h-[calc(100%_-_9%)] pt-3 lg:px-[50px] overflow-y-scroll no-scrollbar">
                  <div className=" bg-[#fff] rounded-[30px] md:w-6/6 sw-5/6 m-auto mt-[20px]">
                    <div className="relative text-center pb-[25px]">
                      <h2 className="text-[#FFFFFF] text-[26px] font-semibold gradient-96  py-3 px-6 rounded-t-[30px]">
                        Phase 2 Introduction
                      </h2>
                      <p className="text-[#5F5982] text-[16px] w-[80%] m-auto font-normal mt-[20px] bg-[#fff]">
                        {content && parse(content)}
                      </p>
                    </div>
                    <div className=" ">
                      <button
                        className="bg-gradient-to-r from-[#2235A1] to-[#121F55] rounded-[10px] py-[10px] px-[80px] text-white mb-[20px]"
                        // onClick={screenDash}
                        onClick={() => {
                          localStorage.removeItem("tabsId");
                          navigate("/user/screening-dashboard");
                        }}
                      >
                        {/* Got it */}
                        {getTranslateText?.data.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "key_got_it"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "key_got_it"
                              )[0]?.keyValue
                            : "Got it"
                          : "Got it"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <ScreeningDashboard />
              )}
            </>
          ) : (
            <>
              <div className="flex justify-center items-center h-[80%]">
                <Loader type="spinningBubbles" color="#2e376c" />
              </div>
            </>
          )}
        </>
      )}
      <ServerUpgradtionModal show={serverErrorModal} setShow={setServerErrorModal} apiCall={getInstruction}/>
    </>
  );
};

export default Screening;

import axios from "axios";
import { baseURL } from "../../../utils/http/http-common";

export const getInterventionStudent = async (userId) => {
  try {
    const response = await axios.get(
      `${baseURL}/Intervention/GetInterventionStudent?UserID=${userId}`
    );
    return response;
  } catch (error) {
    return error.config.data;
  }
};

export const getInterventionDomains = async (userId, studentId) => {
  try {
    const response = await axios.get(
      `${baseURL}/Intervention/GetInerventionDomainWiseDetails?UserID=${userId}&StudentID=${studentId}`
    );
    
    return response;
  } catch (error) {

    return error.config.data;
  }
};

export const getInterventionObservation = async (
  userId,
  studentId,
  domainCategoryId,
  DomainMasterID,
  language_id
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Intervention/GetInerventionClassroomObservation?UserID=${userId}&StudentID=${studentId}&DomainCategoryID=${domainCategoryId}&DomainMasterID=${DomainMasterID}&LanguageID=${language_id}`
    );
    return response;
  } catch (error) {

    throw error;
  }
};

export const postIntervention = async (body) => {
  try {
    const response = await axios.post(
      `${baseURL}/Intervention/PostInterverntion`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInterventionPlan = async (
  domain_id,
  grade_id,
  classroomObservation_id
) => {
  try {
    const response = await axios.get(
      `${baseURL}/Intervention/GetInterventionPlan?DomainId=${domain_id}&GradeID=${grade_id}&ClassroomObservationID=${classroomObservation_id}`
    );
    return response;
  } catch (error) {

    throw error;
  }
};

export const dontAskAgainInstruction = async (userId, instructionId) => {
  try {
    const response = await axios.post(
      `${baseURL}/Intervention/PostDontAskAgain?UserID=${userId}&InstructionID=${instructionId}`
    );
    return response;
  } catch (error) {
    return error.config.data;
  }
};

export const checkProfile = async (userId) => {
  try {
    const response = await axios.post(
      `${baseURL}/Login/CheckProfileCompletion?UserID=${userId}`
    );
    return response;
  } catch (error) {

    throw error.config.data;
  }
};

export const uploadInterventionDoc = async (body) => {

  try {
    const response = await axios.post(
      `${baseURL}/Intervention/PostUploadInterventionDocument`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};

import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import auth from "../../services/auth.service";

const initialState = {
  status: "idle",
  data: [],
  error: false,
};

export const retrieveUserTypes = createAsyncThunk(
  "userTypes/retrieve",
  async () => {
    try {
      const res = await auth.getUserType();
      return res.data;
    } catch (error) {
      return error.config.data;
      console.log(error);
    }
  }
);

const userTypeSlice = createSlice({
  name: "userTypes",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserTypes.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(retrieveUserTypes.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(retrieveUserTypes.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const userTypeReducer = combineReducers({
  userTypeSlice: userTypeSlice.reducer,
});

export default userTypeReducer;

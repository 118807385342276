import { Navigate } from "react-router-dom";
import Profile from "../components/user/profile/Profile";
import Dashboard from "../components/user/Dashboard/Dashboard";
import Certificate from "../components/user/certificate/Certificate";
import Screening from "../components/user/screening/Screening";
import ChangePassword from "../components/user/auth/login/ChangePassword";
import PersonalDetails from "../components/user/screening/PersonalDetails/PersonalDetails";
import ProfilerForm from "../components/user/screening/profilerForm";
import AdvanceScreening from "../components/user/screening/AdvanceScreening/Index";
import InstructionPage from "../components/user/screening/profilerForm/InstructionPage";
import AdvanceReport from "../components/user/screening/AdvanceScreening/AdvanceReport";
import ScreeningDashboard from "../components/user/screening/ScreeningDashboard";
import Report from "../components/user/screening/Report";
import ScreeningQA from "../components/user/screening/ScreeningQA";
import Screening1Report from "../components/user/screening/PersonalDetails/Screening1Report";
import AdvanceScreeningReport from "../components/user/screening/PersonalDetails/AdvanceScreeningReport";
import DashboardLanguage from "../components/user/Dashboard/DashboardLanguage";
import AddStudentForm from "../components/user/screening/AddStudentForm";
import ProfileNameInstruction from "../components/user/profile/ProfileNameInstruction";
import Intervention from "../components/user/intervention/Intervention";
import InterventionDomain from "../components/user/intervention/InterventionDomain";
import InterventionMarkLevels from "../components/user/intervention/InterventionMarkLevels";
import DeleteUser from "../components/deleteUser/DeleteUser";

// import CourseList from "../components/user/course/CourseList";
// import Certificate from "../components/user/certificate/Certificate";

export const privateRoutes = [
  {
    path: "",
    element: <Navigate to={"/user/login"} replace />,
  },
  {
    path: "profile",
    element: <Profile />,
  },

  {
    path: "dashboard",
    element: <Dashboard />,
  },
  // {
  //   path: "course",
  //   element: <CourseList />,
  // },
  {
    path: "certificate",
    element: <Certificate />,
  },
  {
    path: "Screening",
    element: <Screening />,
  },
  {
    path: "screening-dashboard",
    element: <ScreeningDashboard />,
  },
  {
    path: "ChangePassword",
    element: <ChangePassword />,
  },
  {
    path: "ChangeLanguage",
    element: <DashboardLanguage />,
  },
  {
    path: "student-profile",
    element: <PersonalDetails />,
  },
  {
    path: "intervention-student-profile",
    element: <PersonalDetails />,
  },
  {
    path: "profiler-form",
    element: <ProfilerForm />,
  },
  {
    path: "advance-screening",
    element: <AdvanceScreening />,
  },
  {
    path: "instruction",
    element: <InstructionPage />,
  },
  {
    path: "advanceScreeningReport",
    element: <AdvanceReport />,
  },
  {
    path: "screening1-report",
    element: <Screening1Report />,
  },
  {
    path: "screening1-qna",
    element: <ScreeningQA />,
  },
  {
    path: "advance_screening_report",
    element: <AdvanceScreeningReport />,
  },
  {
    path: "add_student_form",
    element: <AddStudentForm />,
  },
  {
    path: "profile_name_instruction",
    element: <ProfileNameInstruction />,
  },
  {
    path: "intervention",
    element: <Intervention />,
  },
  {
    path: "intervention_domains",
    element: <InterventionDomain />,
  },
  {
    path: "intervention_levels_info",
    element: <InterventionMarkLevels />,
  },
  {
    path: "user_account_delete",
    element: <DeleteUser />,
  },
];

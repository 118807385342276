import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import auth from "../../services/auth.service";

const initialState = {
  status: "idle",
  data: [],
  error: false,
};

export const retrieveLanguages = createAsyncThunk(
  "languages/retreive",
  async (_, { rejectWithValue }) => {
    try {
      const res = await auth.getLanguages();
      return res.data;
    } catch (error) {
      console.log(error);
      return error.config.data;
    }
  }
);

const languageSlice = createSlice({
  name: "languages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(retrieveLanguages.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(retrieveLanguages.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(retrieveLanguages.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const languageSelector = (state) => {
  return state.languageReducer.languageSlice;
};

const languageReducer = combineReducers({
  languageSlice: languageSlice.reducer,
});
export default languageReducer;

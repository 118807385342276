import { useState } from "react";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateStudentScreeningStatus } from "../Api/ScreeningApi";
import Loader from "../../../../utils/loader/Loader";
import { toast, ToastContainer } from "react-toastify";

function Screening1(props) {
  const {
    scrData,
    tabName,
    description,
    setShowQna,
    setData,
    tabId,
    tabNumber,
    loader,
  } = props;

  const [showToaster , setShowToaster] = useState(false);
  const revierwerId = JSON.parse(localStorage.getItem("userTypeId"));

  const getTranslateText = useSelector(getTranslatedTextSelector);
  const navigate = useNavigate();

  const updateStudentStatus = async (data) => {
    updateStudentScreeningStatus(data.studentID, tabId)
      .then((res) => {
        if (tabId === 3) {
          if(res === 'Success'){
            navigate("/user/advance-screening", { state: data });
            localStorage.setItem("tabsId", 3);
          }
          else{
          setShowToaster(true);
          toast.warn(res,{style:{fontSize:'14px'}})
            }
        } else {
          navigate("/user/instruction", { state: data });
          localStorage.setItem("tabsId", 2);
        }
      })
      .catch((error) => {
        console.log(error, "errroor");
      });
    // localStorage.setItem("tabsId", 2);
  };

  // Function/Condtion Continue to message //
  const doneConditionMessage = () => {
    if (tabNumber === 1) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "label_screening_2"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "label_screening_2"
            )[0]?.keyValue
          : "Profiler Form"
        : "Profiler Form";
    } else if (tabNumber === 2) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_advance_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_advance_screening"
            )[0]?.keyValue
          : "Advance Screening"
        : "Advance Screening";
    } else {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_intervention"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_intervention"
            )[0]?.keyValue
          : "Advance Intervention"
        : "Advance Intervention";
    }
  };
  // Function/Condtion Continue to message //
  const pendingConditionMessage = () => {
    if (tabNumber === 1) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "first_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "first_screening"
            )[0]?.keyValue
          : "Screening 1"
        : "Screening 1";
    } else if (tabNumber === 2) {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "label_screening_2"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "label_screening_2"
            )[0]?.keyValue
          : "Profiler Form"
        : "Profiler Form";
    } else {
      return getTranslateText?.data?.length > 0
        ? getTranslateText?.data?.filter(
            (item) => item.pageKey === "key_advance_screening"
          )[0]?.keyValue
          ? getTranslateText?.data?.filter(
              (item) => item.pageKey === "key_advance_screening"
            )[0]?.keyValue
          : "Advance Screening"
        : "Advance Screening";
    }
  };

  return (
    <>
      <div className="">
        <div className="lg:p-[0px] md:p-[30px] flex flex-row flex-wrap md:overflow-y-scroll md:h-[calc(100vh-33vh)] fh-[calc(100vh-40vh)] h=[100vh] no-scrollbar">
          {loader !== true ? (
            <>
              {scrData?.length === 0 ? (
                <>
                  <div className="flex flex-col justify-center items-center lg:w-[80%] m-auto py-[0px] h-[100%] ">
                    <img
                      src="../Images/screen1.svg"
                      alt="Screening 1"
                      className="lg:w-[30%] lg:mb-[30px]"
                    />
                    <div>
                      <h3 className="text-[#4D505C] font-semibold text-[18px] mb-[10px]">
                        {/* Screening 1 */}
                        {tabName}
                      </h3>
                      <p className="text-[#5F5982] font-normal text-[16px] mb-[30px]">
                        
                        {description?.description}
                      </p>
                    </div>

                    <button
                      className={`${
                        revierwerId === 9 ? "hiddens" : ""
                      } bg-gradient-to-r from-[#FF9200] to-[#F6BF52] rounded-[10px] text-white py-[10px] lg:px-[80px] px-[40px] text-white"`}
                      // onClick={studentForm}
                      onClick={() => navigate("/user/add_student_form")}
                    >
                      Add Students +
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {scrData.map((data) => (
                    <div className="rounded-[12px] bg-[#fff] xl:w-[44%] lg:w-[43%] w-[100%] lg:mx-[24px] my-[15px] shadow-[0px_0px_20px_#48456C21] h-fit">
                      <div className="flex flex-row justify-between py-4">
                        <p
                          className={` ${
                            data.currentScreeningStatus === "Done"
                              ? "bg-[#E2FFE0]"
                              : "bg-[#FFFAE0]"
                          } ${
                            data.currentScreeningStatus === "Done"
                              ? "text-[#02B10F]"
                              : "text-[#FCA019]"
                          } p-1 rounded-tr-[15px] rounded-br-[15px] text-[12px] px-[15px]`}
                        >
                          {data.currentScreeningStatus === "Done" ? (
                            <>
                              {getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_done"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_done"
                                    )[0]?.keyValue
                                  : "Done "
                                : "Done "}
                            </>
                          ) : (
                            <>
                              {getTranslateText?.data?.length > 0
                                ? getTranslateText?.data?.filter(
                                    (item) => item.pageKey === "label_pending"
                                  )[0]?.keyValue
                                  ? getTranslateText?.data?.filter(
                                      (item) => item.pageKey === "label_pending"
                                    )[0]?.keyValue
                                  : " Pending "
                                : " Pending "}
                            </>
                          )}
                        </p>
                        <p
                          className="text-[#3C57AD] mr-[20px] text-[16px] cursor-pointer"
                          onClick={() => {
                            // setProfileVisible(true);
                            // setGetStudentId(data.studentID);
                            navigate("/user/student-profile", {
                              state: { data },
                            });
                          }}
                        >
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_view_profiler"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) =>
                                    item?.pageKey === "key_view_profiler"
                                )[0]?.keyValue
                              : "View profiler"
                            : "View profiler"}
                        </p>
                      </div>
                      <div className="flex flex-row items-center mx-[30px] mb-[20px]">
                        <img
                          // src="../Images/student-screening-pic.png"
                          src={data.studentPhoto}
                          alt=""
                          className="border-[#b6bacc] rounded-[50%] border-2 w-[70px] h-[70px]"
                        />
                        <div className="flex flex-col text-left pl-[15px]">
                          <p className="mb-[10px] font-medium">
                            {data.studentName}
                          </p>
                          <p className="text-[#848BAA] text-[14px]">
                            {data.grade}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${
                          revierwerId === 9 ? "hiddens" : ""
                        } flex flex-row justify-between bg-[#F4F8FC] px-[30px] py-[10px]"`}
                      >
                        <p
                          className={`${
                            (data.activeScreeningCategoryID === 3) &
                            (data.currentScreeningStatus === "Done")
                              ? "hidden"
                              : "flex"
                          }text-[#101942] text-[16px] text-left `}
                        >
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_continue_with"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "key_continue_with"
                                )[0]?.keyValue
                              : "Continue with"
                            : "Continue with"}
                          &nbsp;
                          {data.currentScreeningStatus === "Done" ? (
                            <> {<p> {doneConditionMessage()}</p>}</>
                          ) : (
                            <> {<p> {pendingConditionMessage()}</p>}</>
                          )}
                        </p>
                        <img
                          alt="arrow"
                          src="../Images/arrow-icon.svg"
                          className={`${
                            (data.activeScreeningCategoryID === 3) &
                            (data.currentScreeningStatus === "Done")
                              ? "w-[0px]"
                              : "w-[40px]"
                          } cursor-pointer
                         
                          `}
                          onClick={
                            () => {
                              setData(data);
                              if (data.currentScreeningStatus === "Pending") {
                                if (data.activeScreeningCategoryID === 1) {
                                  setShowQna(true);
                                } else if (
                                  data.activeScreeningCategoryID === 2
                                ) {
                                  //profiler form
                                  navigate("/user/profiler-form", {
                                    state: data,
                                  });
                                  // updateStudentStatus(data); // need to change //
                                } else {
                                  navigate("/user/advance-screening", {
                                    state: data,
                                  });
                                  // advance screening
                                }
                              } else {
                               
                                if (data.activeScreeningCategoryID === 1) {
                                  updateStudentStatus(data);
                                } else if (
                                  data.activeScreeningCategoryID === 2
                                ) { 
                                  updateStudentStatus(data); // need to change for advance screening
                                } else {
                                  navigate("/user/intervention");
                                }  
                              }
                            }

                          }
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <div className="flex justify-center items-center h-[80%] w-full">
              <Loader type="spinningBubbles" color="#2e376c" />
            </div>
          )}
        </div>
      </div>
      {showToaster && <ToastContainer />}
    </>
  );
}

export default Screening1;

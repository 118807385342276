import React, { useState, useEffect } from "react";
import backIcon from "../../../../assets/images/back-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchInstruction } from "../../../../utils/instruction/InstructionApi";
import { getTranslatedTextSelector } from "../../../../redux/slices/translatedText/getTranslatedText";
import { useSelector } from "react-redux";

function AdvanceIntroductionPage(props) {
  const location = useLocation;
  const [screeningIntro, setScreeningIntro] = useState(true);
  const { setAdvanceIntroPage } = props;
  const navigate = useNavigate();
  const language = localStorage.getItem("l_id");
  const [instruction, setInstruction] = useState();
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const getAdvanceInstruction = (l_id) => {
    fetchInstruction("Advance_Screening", l_id).then((response) => {
      if (response.response[0] === undefined) {
        getAdvanceInstruction(1);
      } else {
        // console.log(response.response[0]);
        setInstruction(response.response[0]);
      }
    });
  };
  const redirectToAdvanceScreeningForm = () => {
    // navigation("/user/", { state: location.state });
    console.log(setAdvanceIntroPage);
    setAdvanceIntroPage(true);
  };

  useEffect(() => {
    getAdvanceInstruction(language);
  }, [language]);
  console.log();

  return (
    <>
      <div className="bg-[#F4F4FA] py-8 h-[calc(100vh_-_9vh)]">
        {screeningIntro ? (
          <>
            {/* Advance Screening Introduction 1 */}
            <div className="rounded-[30px] lg:w-[50%] md:w-[60%] w-[90%] m-auto bg-[#fff] h-full relative">
              <div className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] flex">
                <img
                  src={backIcon}
                  alt="Back Icon"
                  className="w-[20px] mr-5 cursor-pointer"
                  onClick={() => navigate("/user/screening-dashboard")}
                />

                <h2 className="text-xl font-semibold text-left">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "key_advance_screening"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "key_advance_screening"
                        )[0]?.keyValue
                      : "Advance Screening"
                    : "Advance Screening"}
                </h2>
              </div>
              <div className="bg-[#fff] py-5 px-6 text-left">
                <h5 className="text-[#101942] font-medium text-[18px] ">
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "srneLabel"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "srneLabel"
                        )[0]?.keyValue
                      : "Instructions"
                    : "Instructions"}
                </h5>
                <ul className="list-none  text-left [&_li]:text-[#101942cc] [&_li]:py-2 [&_li]:tracking-[0.36px] [&_li]:text-[14px] [&_li]:flex [&_li]:items-baseline ">
                  <li>
                    {/* <img
                      src={bulletsIcon}
                      alt="Bullets Icon"
                      className="mr-2 relative top-[2px] w-[3%]"
                    /> */}
                    {instruction?.description}
                  </li>
                </ul>
              </div>
              <div>
                <button
                  className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] block m-auto absolute bottom-5 left-[30%] w-[40%]"
                  // onClick={() => setScreeningIntro(false)}
                  onClick={() => {
                    setAdvanceIntroPage(false, {
                      state: location,
                    });
                    console.log(location.state);
                  }}
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "key_got_it"
                      )[0]?.keyValue
                      ? getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "key_got_it"
                        )[0]?.keyValue
                      : "Got It!"
                    : "Got It!"}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Advance Screening Introduction 1 
            <div className="rounded-[30px] w-[50%] m-auto bg-[#fff] h-full relative">
              <div>
                <h2 className="text-xl font-semibold bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-6 py-4 rounded-t-[30px] text-left">
                  Advance Screening
                </h2>
              </div>
              <div className="bg-[#fff] py-5 px-6 text-left">
                <h5 className="text-[#101942] font-medium text-[18px] ">
                  Introduction
                </h5>
                <ul className="list-disc px-6 text-left [&_li]:text-[#101942cc] [&_li]:py-2 [&_li]:tracking-[0.36px] [&_li]:text-[14px] ">
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </li>
                  <li>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it
                  </li>
                  <li>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it
                  </li>
                  <li>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it
                  </li>
                  <li>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it
                  </li>
                </ul>

                <label className="flex ml-1 mt-4">
                  <input type="checkbox" className="w-[15px] " />
                  <p className="font-semibold ml-3 text-[14px] text-[#101942]">
                    We kindly request your valuable input
                  </p>
                </label>
              </div>
              <div>
                <button className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] block m-auto absolute bottom-5 left-[30%] w-[40%]">
                  Proceed
                </button>
              </div>
            </div>*/}
          </>
        )}
      </div>
    </>
  );
}

export default AdvanceIntroductionPage;
